import React from 'react'

export default function index() {
    return (
        <div>
            <h1>Gizlilik Politikası</h1>
            <p>
                <strong>Çarpım Tablosu Oyunu: Çöz Eğlen</strong> uygulamasını kullanarak, kişisel bilgilerinizin toplanması,
                kullanılması ve paylaşılması ile ilgili aşağıdaki politikayı kabul etmiş olursunuz. Bu gizlilik politikası,
                uygulama içerisinde toplanan tüm veriler için geçerlidir.
            </p>

            <h2>1. Toplanan Bilgiler</h2>
            <h3>a. Kişisel Bilgiler</h3>
            <p>
                Uygulama üzerinden herhangi bir kişisel bilgi (isim, e-posta adresi vb.) toplamayız. Uygulamamız çocukların
                güvenliği ön planda tutularak tasarlanmıştır ve kişisel veri toplamaz.
            </p>
            <h3>b. Kullanım Bilgileri</h3>
            <p>
                Uygulamanın nasıl kullanıldığını anlamak ve geliştirmek amacıyla bazı anonim kullanım bilgileri
                toplanabilir. Bu
                bilgiler, cihaz türü, işletim sistemi, uygulama içi etkinlikler ve hata raporları gibi verilerdir. Bu
                veriler,
                kullanıcı kimliği belirlemeyen istatistiksel bilgiler içerir.
            </p>

            <h2>2. Bilgilerin Kullanımı</h2>
            <p>
                Toplanan anonim bilgiler aşağıdaki amaçlarla kullanılabilir:
            </p>
            <ul>
                <li>Uygulamanın performansını ve kullanıcı deneyimini iyileştirmek</li>
                <li>Hataları tespit edip düzeltmek</li>
                <li>Yeni özellikler geliştirmek ve mevcut özellikleri iyileştirmek</li>
            </ul>

            <h2>3. Bilgilerin Paylaşımı</h2>
            <p>
                Toplanan anonim veriler, üçüncü şahıslarla paylaşılmaz ve yalnızca uygulamanın geliştirilmesi amacıyla
                kullanılır.
            </p>

            <h2>4. Veri Güvenliği</h2>
            <p>
                Kullanıcı bilgilerinin gizliliği ve güvenliği bizim için önemlidir. Uygulama içerisinde topladığımız
                veriler,
                güvenli sunucularda saklanır ve yetkisiz erişime karşı korunur. Ancak, internet üzerinden veri iletiminin
                %100
                güvenli olamayacağını unutmayın. Bu nedenle, bilgilerinizi korumak için makul önlemler alıyoruz.
            </p>

            <h2>5. Üçüncü Taraf Bağlantıları</h2>
            <p>
                Uygulamamız, üçüncü taraf web sitelerine veya hizmetlerine bağlantılar içerebilir. Bu üçüncü taraf sitelerin
                gizlilik politikalarından sorumlu değiliz. Bu sitelere erişmeden önce, onların gizlilik politikalarını
                gözden
                geçirmenizi öneririz.
            </p>

            <h2>6. Çocukların Gizliliği</h2>
            <p>
                Uygulamamız, çocukların kişisel bilgilerini korumaya yönelik olarak tasarlanmıştır. 13 yaşın altındaki
                çocuklardan kasıtlı olarak kişisel bilgi toplamıyoruz. Eğer bir ebeveyn veya veli olarak, çocuğunuzun bize
                kişisel bilgi sağladığını fark ederseniz, lütfen bizimle iletişime geçin. Bu bilgileri sistemlerimizden
                kaldırmak için gerekli önlemleri alacağız.
            </p>

            <h2>7. Gizlilik Politikasında Değişiklikler</h2>
            <p>
                Bu gizlilik politikasını zaman zaman güncelleyebiliriz. Herhangi bir değişiklik olduğunda, yeni gizlilik
                politikasını bu sayfada yayınlayacağız. Değişikliklerin yürürlüğe girdiği tarih güncelleme tarihidir. Bu
                nedenle, gizlilik politikamızı periyodik olarak gözden geçirmenizi öneririz.
            </p>

            <h2>8. İletişim</h2>
            <p>
                Gizlilik politikamızla ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçin:<br />
                E-posta: <a href="mailto:mfatihtan23@gmail.com">mfatihtan23@gmail.com</a>
            </p>

            <p><strong>Son Güncelleme Tarihi:</strong> [13.07.2024]</p>
        </div>)
}
