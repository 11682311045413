import React from 'react'
import { Outlet } from 'react-router-dom';
import styled from './PrivacyPolicy.module.css';

export default function PrivacyPolicy() {
    return (<>
        <div className={styled.container}>
            <Outlet />
        </div>
        <footer>
            <p>&copy; 2024 mfatihtan.com Tüm Hakları Saklıdır.</p>
        </footer>
    </>
    )
}
