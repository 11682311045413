import React from 'react';
import styled from './Error.module.css';
import { useNavigate } from 'react-router-dom';

export default function Index() {
    const navigate = useNavigate();
    return (
        <div className={styled.body}>
            <div className={styled.view}>
                <h1>Sayfa Bulunamadı!</h1>
                <img className={styled.img404} src='/assets/404.svg' />
            </div>
        </div>
    )
}