import React from 'react'

export default function index() {
    return (
        <div>
            <h1>Gizlilik Politikası</h1>

            <p>AfappSoft olarak bu uygulamayı reklam destekli bir hizmet olarak sunduk. Uygulamamız ücretsizdir ve "olduğu gibi"
                kullanıma sunulmuştur.</p>

            <p>Bu sayfa, hizmetimizi kullanmayı tercih eden kullanıcıları, kişisel bilgilerin toplanması, kullanımı ve ifşası
                konusunda bilgilendirmek amacıyla hazırlanmıştır.</p>

            <p>Hizmetimizi kullanarak bu gizlilik politikası çerçevesinde kişisel bilgilerin toplanmasını ve kullanılmasını
                kabul etmiş olursunuz. Toplanan bilgiler, yalnızca hizmeti sağlamak ve geliştirmek amacıyla kullanılacaktır. Bu
                politikada belirtilen haller dışında bilgileriniz üçüncü kişilerle paylaşılmayacaktır.</p>

            <h2>Bilgi Toplama ve Kullanım</h2>

            <p>Daha iyi bir kullanıcı deneyimi sunabilmek amacıyla, e-posta gibi bazı kişisel bilgileri talep edebiliriz. Bu
                bilgiler güvenli bir şekilde saklanacak ve yalnızca bu politika kapsamında kullanılacaktır.</p>

            <p>Uygulama, kullanıcıyı tanımlayabilecek bilgileri toplayan üçüncü taraf hizmetlerini kullanır.</p>

            <h3>Kullanılan Üçüncü Taraf Hizmet Sağlayıcıları:</h3>
            <ul>
                <li>Google Play Hizmetleri</li>
                <li>AdMob</li>
                <li>Firebase Analytics</li>
                <li>Firebase Crashlytics</li>
            </ul>

            <h2>Günlük Verileri</h2>

            <p>Hizmetimiz sırasında bir hata oluştuğunda, cihazınızın IP adresi, cihaz adı, işletim sistemi sürümü gibi bilgiler
                toplanabilir. Bu veriler sadece hataların tespiti ve analizi için kullanılır.</p>

            <h2>Çerezler</h2>

            <p>Uygulama çerezleri açıkça kullanmaz ancak üçüncü taraf hizmetler çerezler kullanabilir. Çerezleri reddetmeyi
                tercih ederseniz, uygulamanın bazı bölümleri düzgün çalışmayabilir.</p>

            <h2>Hizmet Sağlayıcılar</h2>

            <p>Üçüncü taraf hizmet sağlayıcıları, hizmetimizin sağlanması, iyileştirilmesi ve analiz edilmesi konularında bize
                destek olabilir. Bu taraflar, kendilerine verilen görevler dışında kişisel bilgilerinizi kullanamaz.</p>

            <h2>Güvenlik</h2>

            <p>Kişisel bilgilerinizin güvenliğini sağlamak için ticari olarak kabul edilebilir yöntemler kullanıyoruz, ancak
                internet üzerinden yapılan veri iletimlerinin %100 güvenli olduğunu garanti edemiyoruz.</p>

            <h2>Diğer Sitelere Bağlantılar</h2>

            <p>Uygulamamız, üçüncü taraf sitelere bağlantılar içerebilir. Bu sitelerin gizlilik politikalarından sorumlu
                değiliz.</p>

            <h2>Çocukların Gizliliği</h2>

            <p>Uygulama, 13 yaş altı çocuklara yönelik değildir. Eğer 13 yaş altındaki bir çocuğun bize kişisel bilgi
                sağladığını fark edersek, bu bilgileri derhal sileriz.</p>

            <h2>Gizlilik Politikasındaki Değişiklikler</h2>

            <p>Bu gizlilik politikası zaman zaman güncellenebilir. Değişikliklerden haberdar olmak için bu sayfayı düzenli
                olarak kontrol edebilirsiniz.</p>

            <h2>İletişim</h2>

            <p>Sorularınız varsa <a href="mailto:afappsoftware@gmail.com">afappsoftware@gmail.com</a> adresinden bizimle
                iletişime geçebilirsiniz.</p>
        </div>
    )
}
