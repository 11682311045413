import React from 'react';
import { HomeLayout, PrivacyPolicy } from '../layout'
import {
    ErrorScreen,
    GamingCashPrivacyPolicy,
    HomeScreen,
    MultiplicationPrivacyPolicy,
} from '../screens';
import {
    createBrowserRouter,
} from "react-router-dom";

const routes = createBrowserRouter([
    {
        path: "/",
        children: [
            {
                index: true,
                element: <HomeScreen />
            }
        ]
    },
    {
        path: "/p/",
        element: <PrivacyPolicy />,
        children: [
            {
                path: "multiplication-privacy-policy",
                element: <MultiplicationPrivacyPolicy />
            },
            {
                path: "gamingcash-privacy-policy",
                element: <GamingCashPrivacyPolicy />
            },
            {
                path: "*",
                element: <ErrorScreen />
            },
        ]
    },
    {
        path: "*",
        element: <ErrorScreen />
    }

]);

export default routes;