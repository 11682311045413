import React from 'react';
import styles from './Home.module.css';
const stores = {
    app1: {
        title: 'Gaming Cash | Oyun kodları kazan',
        storeLinkGooglePlay: 'https://play.google.com/store/apps/details?id=com.mfatihtan.gamingcash',
        storeLinkAppStore: '',
    },
    app2: {
        storeLinkGooglePlay: 'https://play.google.com/store/apps/details?id=com.example.app2',
        storeLinkAppStore: 'https://apps.apple.com/us/app/example-app2/id0987654321',
    },
};

const HomeScreen = () => {
    return (
        <div className={styles.container}>
            <h1>Yayınlanmış Uygulamalar</h1>
            <div className={styles.stores}>
                <div className={styles.storeButton}>
                    <a href={"https://play.google.com/store/apps/dev?id=8522450067127814584"} target="_blank" rel="noopener noreferrer">
                        <img src='/assets/google-play.svg' alt='Google Play' className={styles.storeImg} />
                    </a>
                </div>
            </div>
            <div className={styles.appList}>
                <div className={styles.appItem}>
                    <img src='/assets/gamingcash.webp' alt="App 1" className={styles.appImage} />
                    <p>{stores.app1.title}</p>
                    <div className={styles.storeLinks}>
                        {stores.app1.storeLinkGooglePlay !== "" && <a href={stores.app1.storeLinkGooglePlay} target="_blank" rel="noopener noreferrer">
                            Google Play
                        </a>}
                        {stores.app1.storeLinkAppStore !== "" && <a href={stores.app1.storeLinkAppStore} target="_blank" rel="noopener noreferrer">
                            App Store
                        </a>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeScreen;

